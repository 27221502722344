import { Navigate, NavigateProps, useSearchParams } from "react-router-dom";

export const NavigateWithParams = ({
  to,
  ...props
}: { to: string } & Omit<NavigateProps, "to">) => {
  let [searchParams] = useSearchParams();

  return <Navigate to={to + `?${searchParams.toString()}`} {...props} />;
};
