import { PaletteMode, ThemeOptions } from "@mui/material";

const lightTheme: ThemeOptions['palette'] = {
    background: {
        default: "#fefefe"
    }
}

const darkTheme: ThemeOptions['palette'] = {
    background: {
        default: "#212121"
    }
}


export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? lightTheme
            : darkTheme),
    },
});