import { Alert, Box, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useApplicationsContext,
  useOrganizationContext,
} from "@ncr-voyix-commerce/react-common-components";

export const HomePage = () => {
  const { organization, error } = useOrganizationContext();
  const { application } = useApplicationsContext();
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const handleClose = () => setIsSnackOpen(false);

  useEffect(() => {
    if (error) {
      setIsSnackOpen(true);
    }
  }, [error, setIsSnackOpen]);

  return (
    <Box paddingTop={2} display="grid" rowGap="16px">
      <Stack>
        <Typography variant="h6">Current Organization:</Typography>
        <Typography variant="h5">
          {organization
            ? organization?.organizationName
            : "No organization selected"}
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="h6">Current Application:</Typography>
        <Typography variant="h5">
          {application ? application?.displayName : "No application selected"}
        </Typography>
      </Stack>

      <Snackbar
        open={isSnackOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        color="inherit"
      >
        <Alert onClose={handleClose} severity="error">
          {error?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
