import { AppBar, Box, Stack, Theme } from "@mui/material";
import {
  ApplicationSwitcher,
  OrganizationSwitcher,
  UserProfileSettings,
} from "@ncr-voyix-commerce/react-common-components";
import { useOktaAuth } from "@okta/okta-react";
import { LoginButton } from "./login-button/LoginButton";

export const DemoAppBar = () => {
  const { authState } = useOktaAuth();

  return (
    <AppBar
      position="static"
      sx={(theme: Theme) => ({
        px: "var(--app-padding-side)",
        py: "8px",
        top: 0,
        boxShadow: "none",
        left: 0,
        justifyContent: "space-between",
        color: theme.palette.text.primary,
        rowGap: 1,
        columnGap: 2,
        bgcolor: theme.palette.background.default,
        backgroundImage: "none",
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      })}
    >
      <Box marginLeft="-6px">
        <ApplicationSwitcher />
      </Box>

      {/*
       * MARKET MARKET-7571
       * Just making it not break layout for now on mobile.
       * However we should fix this to align more with CX design.
       *
       * Potentially this could be cleaned up in future RCC versions
       * when handling small switcher design updates.
       */}
      {authState?.isAuthenticated ? (
        <Stack direction="row" alignItems="center" columnGap="16px">
          <OrganizationSwitcher />
          <UserProfileSettings />
        </Stack>
      ) : (
        <LoginButton />
      )}
    </AppBar>
  );
};
