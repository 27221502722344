import { Button } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";

export const LoginButton = () => {
  const { oktaAuth } = useOktaAuth();
  const handleClick = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <Button variant="outlined" onClick={handleClick}>
      Login
    </Button>
  );
};
